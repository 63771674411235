$(document).ready(function(){


	var html_selects = $(".selectPickupParam option");
	var html_select_centro = $(".selectPickupParam option[data-ubicacion='0']");

	var html_selects_drop = $(".selectDropParam option");
	var html_select_centro_drop = $(".selectDropParam option[data-ubicacion='0']");


	sel = $(".selectDestinosParametros option:selected").attr('data-ubicacion');
	if(sel == "1"){
		$(".selectsDins").html(html_select_centro);
	    $(".selectsDinsDrop").html(html_select_centro_drop);

	}

	$(".selectDestinosParametros").change(function(){
		$(".selectsDins").html(html_selects);
		$(".selectsDins").val($(".selectsDins option:first").val());
		$(".selectsDinsDrop").html(html_selects_drop);
		$(".selectsDinsDrop").val($(".selectsDinsDrop option:first").val());



		sel = $(".selectDestinosParametros option:selected").attr('data-ubicacion');
		if(sel == "1"){
			$(".selectsDins").html(html_select_centro);
			console.log(html_select_centro);
			$(".selectsDinsDrop").html(html_select_centro_drop);
		}
	});



	$('[data-toggle="popover"]').popover();
	$('body').on('click', function (e) {
	    $('[data-toggle="popover"]').each(function () {
	        //the 'is' for buttons that trigger popups
	        //the 'has' for icons within a button that triggers a popup
	        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
	            $(this).popover('hide');
	        }
	    });
	});
	$('.clockpiker').clockpicker({
	    donetext: 'Done',
	    autoclose: true
    });


 $('#fhInicio').datepicker({
        format: "yyyy-mm-dd",
        todayBtn: "linked",
		language: "es",
		startDate: "-0d"

    }).on('changeDate', function(selected){
        $('#fhInicio').datepicker('hide');
    	$("#fhFin").datepicker('show');
    	 var minDate = new Date(selected.date.valueOf()+ 86400000 );
 		 var tiempo_mas = new Date( selected.date.valueOf() + 86400000 );
    	$('#fhFin').datepicker('setStartDate', minDate);
    	 $('#fhFin').datepicker( 'update', tiempo_mas );

     });

  $('#fhFin').datepicker({
        format: "yyyy-mm-dd",
        todayBtn: "linked",
		language: "es",
		startDate: "+3d"

    }).on('changeDate', function(selected){
    	console.log("cambio");
    	$("#fhFin").datepicker('hide');
    	 $('#fhFin').datepicker( 'update', selected );
    });



});

    function limpiarPanelFlota(){
    	$(".zonaHiddensPanel [name=num_temporadas    ]").val("");
		$(".zonaHiddensPanel [name=sinTarifas        ]").val("");
		$(".zonaHiddensPanel [name=sinOficinas       ]").val("");
		$(".zonaHiddensPanel [name=prioridadDestino  ]").val("");
		$(".zonaHiddensPanel [name=primer_dia_offcell]").val("");
		$(".zonaHiddensPanel [name=offcell_completo  ]").val("");
		$(".zonaHiddensPanel [name=tarifaNeta        ]").val("");
		$(".zonaHiddensPanel [name=tarifaPublica     ]").val("");
		$(".zonaHiddensPanel [name=abrirMinimo       ]").val("");
		$(".zonaHiddensPanel [name=cerrarMaximo      ]").val("");
		$(".zonaHiddensPanel [name=id_categoria      ]").val("");
		$(".zonaHiddensPanel [name=id_proveedor      ]").val("");
		$(".zonaHiddensPanel [name=id_auto           ]").val("");
		$(".zonaHiddensPanel [name=nombreAuto        ]").val("");
		$(".zonaHiddensPanel [name=categoria         ]").val("");
		$(".zonaHiddensPanel [name=onRequest         ]").val("");
		$(".zonaHiddensPanel [name=tipo_cobro        ]").val("");
		$(".check_dispo                               ").val("");
    }


    $(document).on("click",".verCruce",function(){
    	$(".contenidoCruce").html("<center><h1><i class='fa fa-spinner fa fa-3x fa-spin' id='animate-icon'></i></h1></center>");

		var datos = {

			fhInicio : $(".fhInicio").val(),
			fhFin:$(".fhFin").val(),
			ubicacion:$(".ubicacion").val(),
			hora_pu:$(".hora_pu").val(),
			hora_do:$(".hora_pu").val(),
			promocode:'',
			divisa:$(".divisa").val(),
			totalDias:$(".totalDias").val(),
			PROVEEDORES:$(".id_proveedor").val(),
			CATEGORIAS:$(".categoria").val(),
			IATA:$(".IATA").val()

		}


		 $.ajax({

                type: "POST",
                url: "/cruce/",
                data: datos,
                    success: function(data){
                    	console.log(data);
                       $("#contenidoCruce").html(data);


                    },
                    error: function(request, error) {


                    }
                });

    });
    function convertirPesosDolares(cantidad){
    	var divisa          = $(".divisa").val();

    	if (divisa == "USD") {
	    	var tipoCambio      = $(".tipoCambio").val();
	    	var cantidad      =  cantidad / tipoCambio ;
		};

    	return Math.round(cantidad);
    }

    $(document).on("click",".btn_cotizar_auto",function(){
    	limpiarPanelFlota();
    	$(".conDescuento").fadeOut();
    	$(".verCruce").fadeOut();

	   	var btn = $(this);
		var tipoPago         = $(this).attr('data-tipo');
		var banderaOnrequest = $(this).attr('data-onrequest');

		console.log( "banderaOnrequest", banderaOnrequest );

	    	$(".panelDatosAutomovil").fadeIn();
	    	$("body,html").animate({scrollTop:190},900);

		var num_temporadas    = btn.parent().parent().find(".Flota_num_temporadas    ").val();
		var sinTarifas        = btn.parent().parent().find(".Flota_sinTarifas        ").val();
		var sinOficinas       = btn.parent().parent().find(".Flota_sinOficinas       ").val();
		var prioridadDestino  = btn.parent().parent().find(".Flota_prioridadDestino  ").val();
		var primer_dia_offcell= btn.parent().parent().find(".Flota_primer_dia_offcell").val();
		var offcell_completo  = btn.parent().parent().find(".Flota_offcell_completo  ").val();
		var tarifaNeta        = btn.parent().parent().find(".Flota_tarifaNeta        ").val();
		var tarifaPublica     = btn.parent().parent().find(".Flota_tarifaPublica     ").val();
		var tarifaPublicaLabel= btn.parent().parent().find(".Flota_tarifaPublicaLabel").val();
		var abrirMinimo       = btn.parent().parent().find(".Flota_abrirMinimo       ").val();
		var cerrarMaximo      = btn.parent().parent().find(".Flota_cerrarMaximo      ").val();
		var id_categoria      = btn.parent().parent().find(".Flota_id_categoria      ").val();
		var id_proveedor      = btn.parent().parent().find(".Flota_id_proveedor      ").val();
		var id_auto           = btn.parent().parent().find(".Flota_id_auto           ").val();
		var nombreAuto        = btn.parent().parent().find(".Flota_nombreAuto        ").val();
		var categoria         = btn.parent().parent().find(".Flota_categoria         ").val();
		var onRequest         = btn.parent().parent().find(".Flota_onRequest         ").val();
		var tipo_auto         = btn.parent().parent().find(".Flota_tipo_auto         ").val();
		var imagenAuto        = btn.parent().parent().find(".Flota_img_auto          ").val();
		var check_dispo       = btn.parent().parent().find(".Flota_check_dispo       ").val();


		var aire            = btn.parent().parent().find(".aire                      ").val();
		var caracteristicas = btn.parent().parent().find(".caracteristicas           ").val();
		var tramision       = btn.parent().parent().find(".tramision                 ").val();

		console.log( "-----" );
		var perPrep       = btn.parent().parent().find(".perPrep").val();
		console.log( "perPrep", perPrep );

		var cantidadDescuento         = $(".cantidadDeDescuento").val();
		var porcentajeDescuento       = $(".porcentajeDescuento").val();

	    	$(".num_temporadas    ").val(num_temporadas    );
			$(".sinTarifas        ").val(sinTarifas        );
			$(".sinOficinas       ").val(sinOficinas       );
			$(".prioridadDestino  ").val(prioridadDestino  );
			$(".primer_dia_offcell").val(primer_dia_offcell);
			$(".offcell_completo  ").val(offcell_completo  );
			$(".tarifaNeta        ").val(tarifaNeta        );
			$(".tarifaPublica     ").val(tarifaPublica     );
			$(".abrirMinimo       ").val(abrirMinimo       );
			$(".cerrarMaximo      ").val(cerrarMaximo      );
			$(".id_categoria      ").val(id_categoria      );
			$(".id_proveedor      ").val(id_proveedor      );
			$(".id_auto           ").val(id_auto           );
			$(".nombreAuto        ").val(nombreAuto        );
			$(".categoria         ").val(categoria         );
			$(".onRequest         ").val(banderaOnrequest  );
			$(".tipo_cobro        ").val(tipoPago          );
			$(".imagenFinal").attr('src', imagenAuto);
			$(".check_dispo        ").val(check_dispo      );
			$("#perPrep           ").val(perPrep           );


				var tarifaPordia        = tarifaPublicaLabel;
				var tarifaTotalOriginal = tarifaPublica * $(".totalDias").val();
				var tempTarifaTotal     = tarifaPordia * $(".totalDias").val();



			if (tipoPago =="2" && porcentajeDescuento != 0) {


				var tarifaTotalPagar = Math.round(tarifaTotalOriginal * porcentajeDescuento);

				$(".precio-pagar-final").html(tarifaTotalPagar,2);
				$(".cantidadDescuento").html( (tarifaTotalOriginal * cantidadDescuento)/100);
				$(".conDescuento").fadeIn();



			}else{


				$(".precio-pagar-final").html(tempTarifaTotal);



			}

			if (num_temporadas > 1) {
				//$(".verCruce").fadeIn();
			};

			$(".precio-total-final").html(tempTarifaTotal);
			$(".precio-total-porDia").html(tarifaPordia);

			$(".LabeltotaldeDias").html($(".totalDias").val());
			$(".ttipo").html(tipo_auto);
			$(".mmodelo").html(nombreAuto);

			$(".llista").html("<li>"+aire+"</li><li>"+tramision+"</li>");
			$(".masCaracteristicas").html(caracteristicas);

	});
$(document).on("change","input[name=lugar_diferente]",function(){
 mostrarDevolucionDiferente();
});
function mostrarDevolucionDiferente(){
 $(".contenedor_ubicacion_do").hide();
 if($("input[name=lugar_diferente]").length){
  if($("input[name=lugar_diferente]").is(':checked')){
   $(".contenedor_ubicacion_do").show();
  }
 }
}
$().ready(function(){mostrarDevolucionDiferente()});

/***********************DESTINOS******************/
	$(document).on("click",".d1a",function(){


		$(".contenedorDestino").html("<center><img src='/img/load.gif'></center>");
//		$(".loadingGif").fadeIn();

		 var IATASIN=Array();





			var div            = $(this);
			var url            = div.attr('data-url');
			var destino        = div.attr('data-destino');
			var IATA           = div.attr('data-IATA');
			var destinoGuiones = div.attr('data-destinoGuiones');
			var tst="";

			var urlImagen = g_RepositorioDestinos+"Destinos/"+destinoGuiones+"/img/imagen_corto.jpg";

			var divRes         = $("#cambiaDestino");
			var imgContenedor  = divRes.find( '.displayImagenes' );
			var textContenedor = divRes.find( '.txtdestiny' );
			var shadow         = divRes.find( '.imgdes2' );

			var datos   = {
			            	url: url,
			            	dominio: 'abccarrentalmexico.com',
			            	destino: destino,
			            	destinoGuiones: destinoGuiones
	            		}


            imgContenedor.hide();
            textContenedor.hide();
            shadow.hide();


                $.ajax({
                    type: "GET",
                    data: datos,
                    url: "/ContenidoDestinos/",
                    success: function(msj){
                    	$(".cambiaDestino").fadeIn();
						$(".loadingGif").fadeOut();

                    	$(".contenedorDestino").html(msj);
                        //dividirs
                        $(".imgdes").attr('src', urlImagen);
                        $(".imgdes").attr('alt', destino);
                        $(".imgdes").toggle("hidde");

                        $(".imgdes").toggle("show");
                       divRes.find('.loadingesp').remove();
                        textContenedor.show();
                        shadow.show();
                        imgContenedor.show();

                    },
                    error: function(request, error) {
                        $("#cambiaDestino").html("<div id='errorJS'>ERROR " +request.status + request.responseText+"<div>");
                    }
                });
        $('body,html').animate({
          scrollTop: 100
        },700);
       });
/*****************datos**************************/

 $(document).on("click",".verRequisitos",function(){
	$(".textoRequisitos").toggle(500);

 });
 $(document).on("click",".btnProcesarPago",function(){
 	//$(".datosCliente").fadeOut();
 	//$(".procesandoReserva").fadeIn();

 });


    $(document).ready(function() {

	var bandera_existe_reglas     = false;
	var bandera_existe_aeropuerto = false;
	var bandera_pagar_credito     = false;
	var bandera_existe_credito    = false;
	var bandera_bancos            = false;

	$('#form-datos').submit( function () {

		this.valor            = 1;
		bandera_existe_reglas = true;

		this.datos = $('#formulario-vuelo').is(':visible');

		if ( this.datos ) {
			this.valor = 2;
		}else{
			this.valor = 1;
		}

//alert("antes de validar");

		this.validar = validar( this.valor );

//alert("despues del this");
//console.log(this.validar);
		if ( !this.validar ) { //no te deja seguir
		//	alert("entre en false");
			return false;

		}else{// te deja seguir
		//	alert("me dejo pasar");
			$(".datosCliente").fadeOut();
		 	$(".procesandoReserva").fadeIn();
		 	$('.btnProcesarPago').prop( "disabled", true );
			return true;
		}
		return false;
	});

	$('#checkbox').change(function() {

		if ( $(this).is(':checked') ) {
			$('#formulario-vuelo').removeClass('ocultar');

			if ( bandera_existe_reglas ) {
				crearValidacionAeropuerto();
				bandera_existe_aeropuerto = true;
			}

		}else{
			$('#formulario-vuelo').addClass('ocultar');

			if ( bandera_existe_reglas ) {
				eliminarValidacionAeropuerto();
			}

		}

	});

	$("#pagoCheck").on("click", function(){
	this.bandera_tarjeta_pago = 1;

		if($(this).attr('checked', true))
			$(".pagoDiv").toggle();

		if ( $('#checkboxDes').is(':checked') ) {

			if ( bandera_pagar_credito ) {


				crearValidacionCredito();
			}
			this.bandera_tarjeta_pago = 1;

		} else{

			if ( bandera_pagar_credito ) {
				eliminarValidacionCredito();


			}
			this.bandera_tarjeta_pago = 0;

		}
		$('#bandera_tarjeta_pago').val( this.bandera_tarjeta_pago );

	});

	$('#cont_tarjeta_credito').card({
	    container: '#card-container',
	});


	$('#number').blur(function(event) {
		this.clases               = $('.card').attr('class');
		this.temp_division_clases = this.clases.split(" ");

		console.log( "clases", this.temp_division_clases );

		if ( this.temp_division_clases[1] == "unknown" ) {
			$('#tipo_tarjeta').val("");
		}else{

			if ( this.temp_division_clases[1] == "amex" || this.temp_division_clases[2] == "amex" ) {
				$('#tipo_tarjeta').val("amex");
			}else{
				$('#tipo_tarjeta').val("banamex");
			}

		}


	});


//$('#form-datos').validate();
	function validar( datos ) {
		/*if ( $.validate ) {
			alert( "TEST" );
		}else{
			alert( "TEST 2" );

			return false;
		}*/
		$('#form-datos').validate({
	        errorElement: "span",
	        errorPlacement: errorInputLabel,
	        errorClass: 'error-datos',
	        rules: {
	            "nombre" : {
	                required : true,
	            },
	            "correo" : {
	                required : true,
	                email: true
	            },
	            "ciudad" : {
	            	required: true,
	            },
	            "telefono" : {
	            	required: true,
	            },
	            "apellidos" : {
	            	required: true,
	            },
	            "celular" : {
	            	required: true,
	            },
	            "tracking" : {
	            	required: true,
	            }
	        }
	    }).form();


		if ($(".tipo_cobro_POST").val()==2) {
		//	alert("valido bancos");
			var banderaEnBancos=	crearValidacionCredito();
		//	alert(banderaEnBancos);
			if (banderaEnBancos == false) {
				return false;
			};
		}

		var validar_terminos = $('#checkbox2').is(':checked');

		if ( !validar_terminos ) {
			$('#mensaje_terminos').show();

			return false;

		}else{

			$('#mensaje_terminos').hide();
		}



	    return $("#form-datos").valid();

	}

	function crearValidacionAeropuerto() {
		$('#aerolinea').rules('add', {
			required: true,
		});

		$('#arribo').rules('add', {
			required: true,
		});

		$('#no_vuelos').rules('add', {
			required: true,
		});

		$('#origen_vuelos').rules('add', {
			required: true,
		});
	}

	function eliminarValidacionAeropuerto() {
		$( "#aerolinea" ).rules( "remove" );
		$( "#arribo" ).rules( "remove" );
		$( "#no_vuelos" ).rules( "remove" );
		$( "#origen_vuelos" ).rules( "remove" );
	}

	function crearValidacionCredito() {


	   $('#number').rules('add', {
			required: true,
		});

		$('#name').rules('add', {
			required: true,
		});

		$('#expiry').rules('add', {
			required: true,
		});

		$('#cvc').rules('add', {
			required: true,
			number: true,
		});

		//bandera_existe_credito = true;

		return $("#form-datos").valid();
	}

	function eliminarValidacionCredito() {
		$( "#tarjeta_credito" ).rules( "remove" );
		$( "#nombre_tarjeta" ).rules( "remove" );
		$( "#expiry" ).rules( "remove" );
		$( "#cvc" ).rules( "remove" );

		bandera_existe_credito = false;

	}


	var errorInputLabel = function(error, element) {
        if( element.parent('.input-group').length ) {
            error.insertAfter( element );
        } else {
            error.insertAfter( element );
        }
    }



});
 function llenarCampos(){
    	$("#nombre").val("Milagros saldivar"+Math.floor((Math.random() * 1200) + 1));
    	$("#apellidos").val("saldivar");
    	$("#correo").val("msaldivar@mexicocarrental.com.mx");
    	$("#telefono").val("8888");
    	$("#celular").val("9999");
    	$("#ciudad").val("cancun");
    	$("#tracking").val("Google");
    	$("#comentarios").text("testsetes");
    	$("#checkbox2").click();
    	$("#number").val("345678000000007");
    	$("#name").val("name");
    	$("#expiry").val("0517");
    	$("#cvc").val("1234");



    }
